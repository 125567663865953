<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <div class="row">
            <div class="col-md-6 d-flex align-items-stretch mb-3">
                <div class="row flex-grow">
                    <div class="col-12">
                        <div class="text-left">
                            <b-btn v-on:click="resetForm" variant="primary">
                                {{ $t('add-spot-type-btn') }}
                            </b-btn>
                        </div>
                    </div>
                </div>
            </div>
            <b-modal id="modalmd" ref="vuemodal" :title="title" size="md" hide-footer>
                <form class="forms-sample" @submit.stop.prevent="onSubmit" autocomplete="off">
                    <b-form-group :label="$t('name*')" label-for="name">
                        <b-form-input type="text" id="name" name="name" autocomplete="new-password" :placeholder="$t('name-placeholder')" v-model="$v.form.name.$model" :state="$v.form.name.$dirty ? !$v.form.name.$error : null" aria-describedby="input-1-live-feedback">
                        </b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{ $t("field-required") }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-button type="submit" variant="success" :disabled="isDisabled" class="mr-2">
                        <span v-if="form.id == ''">{{ $t('submit-btn') }}</span>
                        <span v-else>{{ $t('update-btn') }}</span>
                    </b-button>
                </form>
            </b-modal>
        </div>
        <div class="row">
            <div class="col-lg-12 stretch-card">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex align-items-center question-order row-question-order">
                            <h4 class="card-title">{{ $t('spot-types') }}</h4>
                            <div class="header-buttons">
                                <button class="btn btn-primary" @click="showArchieved">{{ $t('Archived') }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body new-card-body">
                        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :isLoading.sync="isLoading" @on-search="searchFn" :search-options="{enabled: true,searchFn: searchFn}"
                            :pagination-options="{
                                enabled: true,
                                dropdownAllowAll: false,
                                perPageDropdown: [10, 20, 50, 100, 200],
                            }" :rows="rows" :columns="tableColumns" :line-numbers="true">
                            <template slot="table-row" slot-scope="props">
                                <span
                                    v-if="props.column.field === 'action'"
                                    class="text-nowrap"
                                >
                                <toggle-button
                                v-model="spots.find(p => p.id === props.row.id).show"
                                @change="changeStatus(props.row.id, $event)"
                                :sync="true"
                                class="mr-1 switchColor"/>
                                <b-button size="sm"
                                    @click="editSpot(props.row.id)"
                                    class="mr-1 btn-info text-white">
                                    {{ $t('edit-btn') }}
                                </b-button>
                                <b-button size="sm" @click="deleteSpot(props.row.id)" class="mr-1 btn-danger text-white">
                                    {{ $t('delete-btn') }}
                                </b-button>
                                <toggle-button
                                v-if="version_id === 2 && is_pro_sync === 1"
                                v-model="spots.find(p => p.id === props.row.id).is_sync"
                                @change="changeSyncStatus(props.row.id, $event)"
                                :sync="true"
                                class="mr-1 switchColor"/>
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Vue from "vue";
    import SortedTablePlugin from "vue-sorted-table";
    import API from '@/api'
    import Snotify, {
        SnotifyPosition
    } from 'vue-snotify'
    import {
        validationMixin
    } from "vuelidate";
    import {
        required,
        minLength,
        email,
        requiredIf,
        sameAs
    } from "vuelidate/lib/validators";
    import moment from 'moment';
    import i18n from '@/i18n';
    
    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    Vue.use(SortedTablePlugin, {
        ascIcon: '<i class="ti-arrow-down"></i>',
        descIcon: '<i class="ti-arrow-up"></i>'
    });
    export default {
        name: 'Spot-Type',
        mixins: [validationMixin],
        data() {
            return {
                form: {
                    name: "",
                    id: "",
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    show: true,
                    is_sync: true,
                },
                isDisabled: false,
                title: i18n.t('add-spot-type'),
                serverParams: {
                    // a map of column filters example: {name: 'john', age: '20'}
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    columnFilters: {},
                    sort: [{
                        field: 'id', // example: 'name'
                        type: 'desc' // 'asc' or 'desc'
                    }],
    
                    page: 1, // what page I want to show
                    perPage: 10 // how many items I'm showing per page
                },
                columns: [{
                        label: 'Name',
                        field: 'name',
                        filterable: true
                    },
                    {
                        label: 'Created At',
                        field: 'created_at',
                        sortable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: 'Updated At',
                        field: 'updated_at',
                        sortable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: 'Show/ Action / Sync',
                        field: "action",
                        sortable: false,
                    },
                ],
                isLoading: false,
                rows: [],
                totalRecords: 0,
                spots: [],
                version_id: null,
                is_pro_sync: '',
            };
        },
        validations: {
            form: {
                id: {},
                name: {
                    required,
                },
            }
        },
        methods: {
            showArchieved() {
                this.$router.push("/spot-types/archived", () => {});
            },
            changeStatus(id, event) {
                console.log(id, event.value);
                const data = {
                    id: id,
                    show: event.value === true ? 1 : 0
                };
                API.updateSpotTypeStatus(
                    data,
                    data => {
                        this.loadItems();
                        if (data.status === 200) {
                            this.$snotify.success(data.message);
                        }
                    },
                    err => {}
                );
            },
            changeSyncStatus(id, event) {
                console.log(id, event.value);
                const data = {
                    id: id,
                    is_sync: event.value === true ? 1 : 0
                };
                API.updateSpotTypeSync(
                    data,
                    data => {
                        this.loadItems();
                        if (data.status === 200) {
                            this.$snotify.success(data.message);
                        }
                    },
                    err => {}
                );
            },
            deleteSpot(e) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        API.deleteSpot(
                            e,
                            data => {
                                this.loadItems();
                                this.$snotify.success(data.message);
                            },
                            err => {})
                    }
                })
            },
            editSpot(e) {
                this.$v.form.$reset();
                this.$root.$emit("bv::show::modal", "modalmd");
                this.title = i18n.t('edit-spot-type');
                API.getEditSpotType(
                    e, data => {
                        this.form.name = data.name;
                        this.form.id = data.id;
                        this.form.show = (data.data.show == 1) ? true : false;
                        this.form.is_sync = (data.data.is_sync == 1) ? true : false;
                    },
                    err => {}
                )
            },
            onSubmit() {
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }
                if (this.form.name != '') {
                    this.isDisabled = true;
                    API.addEditSpotType(
                        this.form,
                        data => {
                            this.$refs["vuemodal"].$refs['close-button'].click();
                            if (data.status === 200) {
                                this.$snotify.success(data.message);
                            } else {
                                this.$snotify.error(data.message);
                            }
                            this.loadItems();
                            this.isDisabled = false;
                            this.form.name = '';
                            this.form.id = '';
                            this.form.show = true;
                            this.form.is_sync = true;
                            this.$v.form.$reset();
                        },
                        err => {
                            this.isDisabled = false;
                            this.$snotify.error(err, {
                                timeout: 2000,
                                showProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    )
                } else {
                    this.$snotify.warning('Please fill required fields', {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    this.isDisabled = false;
                }
            },
            loadItems() {
                this.isLoading = true;
                API.getAllSpotType(this.serverParams,
                    data => {
                        this.isLoading = false;
                        this.totalRecords = data.totalRecords;
                        this.rows = data.data.data;
                        this.version_id = data.version_id;
                        this.is_pro_sync = data.is_pro_sync;
                        this.rows.forEach(q => {
                            const data = {
                                id: q.id,
                                show: q.show === 1 ? true : false,
                                is_sync: q.is_sync === 1 ? true : false 
    
                            };
                            this.spots.push(data);
                            console.log('this.spots',this.spots);
                        });
                    },
                    err => {}
                )
            },
            resetForm() {
                this.title = i18n.t('add-spot-type');
                this.form.name = '';
                this.form.id = '';
                this.$v.form.$reset();
                this.$root.$emit("bv::show::modal", "modalmd");
            },
            formatDate(value) {
                if (value) {
                    return moment(String(value)).format('YYYY-MM-DD')
                }
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
    
            onPageChange(params) {
                this.updateParams({
                    page: params.currentPage
                });
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({
                    perPage: params.currentPerPage
                });
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },
            searchFn(params) {
                this.updateParams({
                    searchTerm: params
                });
                this.loadItems();
                this.isLoading = false;
            },
            formatStatus(status) {
                if (status === 1) {
                    return true;
                } else {
                    return false;
                }
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
        },
        computed: {
            tableColumns() {
                let columns;
                if (this.version_id === 2 && this.is_pro_sync === 1) {
                    columns = [
                        {
                            label: 'Name',
                            field: 'name',
                            filterable: true
                        },
                        {
                            label: 'Created At',
                            field: 'created_at',
                            sortable: true,
                            formatFn: this.formatDate
                        },
                        {
                            label: 'Updated At',
                            field: 'updated_at',
                            sortable: true,
                            formatFn: this.formatDate
                        },
                        {
                            label: 'Show / Action / Sync',
                            field: "action",
                            sortable: false,
                        },
                    ];

                }
                else{
                    columns = [
                        {
                            label: 'Name',
                            field: 'name',
                            filterable: true
                        },
                        {
                            label: 'Created At',
                            field: 'created_at',
                            sortable: true,
                            formatFn: this.formatDate
                        },
                        {
                            label: 'Updated At',
                            field: 'updated_at',
                            sortable: true,
                            formatFn: this.formatDate
                        },
                        {
                            label: 'Show / Action',
                            field: "action",
                            sortable: false,
                        },
                    ];
                }

                console.log('columns',columns);
                return columns;
            },
        },
        mounted() {
            this.isLoading = true;
            this.loadItems();
        }
    }
</script>


<style>
    @media (max-width: 1150px) {
        .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
</style>